var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center" },
    [
      _c("vs-avatar", {
        staticClass: "flex-shrink-0 mr-2",
        attrs: { src: _vm.params.data.avatar, size: "30px" },
        on: {
          click: function ($event) {
            return _vm.$router.push(_vm.url)
          },
        },
      }),
      _c(
        "router-link",
        {
          staticClass: "text-inherit hover:text-primary",
          attrs: { to: _vm.url },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
            },
          },
        },
        [_vm._v(_vm._s(_vm.params.value))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }