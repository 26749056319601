var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("feather-icon", {
    attrs: {
      icon: "CircleIcon",
      svgClasses: ["fill-current h-4 w-4", _vm.textColor],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }