var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-user-list" } },
    [
      _c(
        "vx-card",
        {
          ref: "filterCard",
          staticClass: "user-list-filters mb-8",
          attrs: { title: "Filters", actionButtons: "" },
          on: { refresh: _vm.resetColFilters, remove: _vm.resetColFilters },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 sm:w-1/2 w-full" },
              [
                _c("label", { staticClass: "text-sm opacity-75" }, [
                  _vm._v("Role"),
                ]),
                _c("v-select", {
                  staticClass: "mb-4 md:mb-0",
                  attrs: {
                    options: _vm.roleOptions,
                    clearable: false,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.roleFilter,
                    callback: function ($$v) {
                      _vm.roleFilter = $$v
                    },
                    expression: "roleFilter",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 sm:w-1/2 w-full" },
              [
                _c("label", { staticClass: "text-sm opacity-75" }, [
                  _vm._v("Status"),
                ]),
                _c("v-select", {
                  staticClass: "mb-4 md:mb-0",
                  attrs: {
                    options: _vm.statusOptions,
                    clearable: false,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.statusFilter,
                    callback: function ($$v) {
                      _vm.statusFilter = $$v
                    },
                    expression: "statusFilter",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 sm:w-1/2 w-full" },
              [
                _c("label", { staticClass: "text-sm opacity-75" }, [
                  _vm._v("Verified"),
                ]),
                _c("v-select", {
                  staticClass: "mb-4 sm:mb-0",
                  attrs: {
                    options: _vm.isVerifiedOptions,
                    clearable: false,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.isVerifiedFilter,
                    callback: function ($$v) {
                      _vm.isVerifiedFilter = $$v
                    },
                    expression: "isVerifiedFilter",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 sm:w-1/2 w-full" },
              [
                _c("label", { staticClass: "text-sm opacity-75" }, [
                  _vm._v("Department"),
                ]),
                _c("v-select", {
                  attrs: {
                    options: _vm.departmentOptions,
                    clearable: false,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.departmentFilter,
                    callback: function ($$v) {
                      _vm.departmentFilter = $$v
                    },
                    expression: "departmentFilter",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "vx-card p-6" },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center" },
            [
              _c(
                "div",
                { staticClass: "flex-grow" },
                [
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer",
                      attrs: { "vs-trigger-click": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentPage * _vm.paginationPageSize -
                                  (_vm.paginationPageSize - 1)
                              ) +
                                " - " +
                                _vm._s(
                                  _vm.usersData.length -
                                    _vm.currentPage * _vm.paginationPageSize >
                                    0
                                    ? _vm.currentPage * _vm.paginationPageSize
                                    : _vm.usersData.length
                                ) +
                                " of " +
                                _vm._s(_vm.usersData.length)
                            ),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon: "ChevronDownIcon",
                              svgClasses: "h-4 w-4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.paginationSetPageSize(10)
                                },
                              },
                            },
                            [_c("span", [_vm._v("10")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.paginationSetPageSize(20)
                                },
                              },
                            },
                            [_c("span", [_vm._v("20")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.paginationSetPageSize(25)
                                },
                              },
                            },
                            [_c("span", [_vm._v("25")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.paginationSetPageSize(30)
                                },
                              },
                            },
                            [_c("span", [_vm._v("30")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("vs-input", {
                staticClass:
                  "sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4",
                attrs: { placeholder: "Search..." },
                on: { input: _vm.updateSearchQuery },
                model: {
                  value: _vm.searchQuery,
                  callback: function ($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery",
                },
              }),
              _c(
                "vs-dropdown",
                {
                  staticClass: "cursor-pointer",
                  attrs: { "vs-trigger-click": "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32",
                    },
                    [
                      _c("span", { staticClass: "mr-2 leading-none" }, [
                        _vm._v("Actions"),
                      ]),
                      _c("feather-icon", {
                        attrs: {
                          icon: "ChevronDownIcon",
                          svgClasses: "h-4 w-4",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-dropdown-menu",
                    [
                      _c("vs-dropdown-item", [
                        _c(
                          "span",
                          { staticClass: "flex items-center" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-2",
                              attrs: {
                                icon: "TrashIcon",
                                svgClasses: "h-4 w-4",
                              },
                            }),
                            _c("span", [_vm._v("Delete")]),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-dropdown-item", [
                        _c(
                          "span",
                          { staticClass: "flex items-center" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-2",
                              attrs: {
                                icon: "ArchiveIcon",
                                svgClasses: "h-4 w-4",
                              },
                            }),
                            _c("span", [_vm._v("Archive")]),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-dropdown-item", [
                        _c(
                          "span",
                          { staticClass: "flex items-center" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-2",
                              attrs: {
                                icon: "FileIcon",
                                svgClasses: "h-4 w-4",
                              },
                            }),
                            _c("span", [_vm._v("Print")]),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-dropdown-item", [
                        _c(
                          "span",
                          { staticClass: "flex items-center" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-2",
                              attrs: {
                                icon: "SaveIcon",
                                svgClasses: "h-4 w-4",
                              },
                            }),
                            _c("span", [_vm._v("CSV")]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              components: _vm.components,
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.usersData,
              rowSelection: "multiple",
              colResizeDefault: "shift",
              animateRows: true,
              floatingFilter: true,
              pagination: true,
              paginationPageSize: _vm.paginationPageSize,
              suppressPaginationPanel: true,
              enableRtl: _vm.$vs.rtl,
            },
          }),
          _c("vs-pagination", {
            attrs: { total: _vm.totalPages, max: 7 },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }